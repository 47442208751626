* {
  margin: 0;
  padding: 0;
}

body {
  min-height: 100vh;
  background: $background-body;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 18px;
  line-height: 1.5;
}

.container {
  background: $background-container;
  max-width: 80vw;
  margin: 0 auto;
  padding: 0 0 3em;
}

.section {
  margin: 0 auto 3em;
  width: 85%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $primary-color;
  padding: 2em 0 1em;
  text-align: center;
}

p,
a,
i,
span {
  line-height: 1.5em;
  font-size: 18px;
}

p {
  padding: 1em 0;
}

a {
  text-decoration: none;
  transition: all 0.7s;
  color: $primary-color;
}

a:hover {
  color: $link;
  text-decoration: underline;
}

ul,
ol {
  margin: 0.5em 0 1em 1.5em;

  li::marker {
    color: $primary-color;
  }
}

img {
  width: 100%;
}

.error {
  img {
    height: 20em;
    object-fit: cover;
    object-position: center;
  }
}

strong {
  color: $primary-color;
}

@media (min-width: $big-screen-min-width) {
  .container {
    max-width: $big-screen-width;
  }
}
