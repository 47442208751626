.nav-wrapper {
  background: $primary-color;
  position: sticky;
  top: 0;
  z-index: $z-index-sticky-nav;
}

nav {
  display: flex;
  justify-content: space-evenly;
  margin: 0 auto;
  max-width: $big-screen-width;

  a {
    align-items: baseline;
    color: $white;
    display: flex;
    flex-grow: 1;
    padding: 10px 20px;
    text-decoration: none;
    transition: background 0.7s;

    i {
      font-size: 24px;
      padding: 0 0.8em 0 0;
    }

    span {
      font-size: 24px;
    }
  }

  a:hover {
    background: $secondary-color;
    color: $white;
    text-decoration: none;
  }
}

@media (max-width: $mobile-max-width) {
  nav {
    align-items: baseline;
    background: $primary-color;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    padding: 0 0 0 75px;
    transition: all 0.5s ease;

    a {
      flex-grow: inherit;

      i {
        font-size: 30px;
      }

      span {
        font-size: 30px;
      }
    }
  }

  .toggle {
    height: 0;
    transition: all 0.5s ease, visibility 0.2s;
    visibility: hidden;
  }
}
