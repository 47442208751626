.entry {
  .entry-content {
    background: $primary-color;
    max-width: 80%;
    margin: -4em auto 0;
    position: relative;
    opacity: 0.9;

    h1 {
      color: $white;
      font-size: 38px;
      padding: 1.5em 2em 1em;
    }

    p {
      color: $white;
      font-size: 18px;
      padding: 0 2em 2em;
    }
  }
}

@media (max-width: $mobile-max-width) {
  .home {
    padding: 0;
  }

  .entry {
    .entry-content {
      margin: -3em auto 0;
      max-width: 90%;

      h1 {
        font-size: 25px;
        padding: 1em;
      }

      p {
        padding: 0 1em 1em;
      }
    }
  }
}
