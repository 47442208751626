@media (max-width: $mobile-max-width) {
  .container {
    max-width: 100%;
    padding: 0;
  }

  header {
    .logo {
      display: none;
    }
  }

  .section {
    margin: 0;
    width: 100%;
  }

  .entry {
    padding: 0 1.5em 2.5em;
  }

  .back {
    padding: 0 0 2em 2em;
    width: auto;
  }

  table {
    max-width: 100%;
  }
}
