::-webkit-scrollbar {
  width: 15px;
  background: $primary-color;
}

::-webkit-scrollbar-track {
  background: $lightgray;
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: $primary-color;
  box-shadow: inset 0 0 5px grey;
  border-radius: 7.5px;
}

::-webkit-scrollbar-thumb:hover {
  background: $secondary-color;
}
