footer {
  background: $primary-color;
  bottom: 0;
  display: flex;
  justify-content: space-around;
  left: 0;
  position: fixed;
  right: 0;

  div {
    display: flex;
    width: $big-screen-width;
  }

  a {
    align-items: baseline;
    color: $white;
    display: flex;
    flex-grow: 1;
    font-size: 16px;
    padding: 1em 2em;
    text-decoration: none;
    transition: background 0.7s;

    i {
      padding: 0 0.8em 0 0;
    }

    .fa-heart {
      transition: color 0.7s;
    }
  }

  a:hover {
    background: $secondary-color;
    color: $white;
    text-decoration: none;

    .fa-heart {
      color: $red;
    }
  }
}

@media (max-width: $mobile-max-width) {
  footer {
    position: inherit;

    div {
      display: flex;
      flex-direction: column;
      margin: 1em 0;
      width: auto;
    }

    a {
      font-size: 18px;
      padding: 0.7em 2em;

      .fa-info {
        margin: 0 0 0 0.3em;
      }

      .fa-info + span {
        margin: 0 0 0 0.4em;
      }

      .book-reader {
        margin: 0 0 0 0.1em;
      }
    }
  }
}
