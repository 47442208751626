.back {
  a {
    i {
      background: $primary-color;
      border-radius: 0.15em;
      color: $white;
      padding: 0.2em 1.2em;
      transition: all 0.5s ease;
    }

    i:hover {
      background: $secondary-color;
    }
  }
}

.detail {
  img {
    border-radius: 50px 5px;
    height: 320px;
    object-fit: cover;
    object-position: center;
    width: 100%;
  }

  .download {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;

    a {
      align-items: center;
      border: 0.3em solid $primary-color;
      border-radius: 0.5em;
      display: flex;
      flex-direction: column;
      padding: 1.5em;
      margin: 1em;

      i {
        font-size: 50px;
      }

      span {
        font-size: 22px;
        margin: 1em 0 0;
      }
    }

    a:hover {
      text-decoration: none;
      border: 0.3em solid $secondary-color;
    }
  }
}

@media (max-width: $mobile-max-width) {
  .detail {
    img {
      border-radius: 0;
    }

    .download {
      a:first-of-type {
        display: none;
      }
    }
  }
}
