// Default
@import "includes/variables";
@import "includes/defaults";
@import "includes/defaults_mobile";
@import "includes/header";
@import "includes/navigation";
@import "includes/footer";
@import "includes/hamburger";
@import "includes/scrollbar";
@import "includes/swup";

// Icons
@import "../fontawesome/css/all.min.css";

// Sites
@import "sites/login";
@import "sites/home";
@import "sites/contact";
@import "sites/impressum";

// Modules
@import "modules/offer";
@import "modules/detail";
@import "modules/news";
