.list {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;

  .element {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 1em;

    h2 {
      font-size: 40px;
      text-transform: uppercase;
      position: absolute;
      padding: 0;
      margin: 0;
      max-width: 390px;
      word-break: break-word;
    }

    img {
      border-radius: 50px 5px;
      object-fit: cover;
      opacity: 1;
      filter: grayscale(10%);
      height: 250px;
      transition: all 0.7s ease;
      width: 400px;
    }

    img:hover {
      opacity: 0.2;
      filter: grayscale(90%);
    }
  }
}

@media (max-width: $mobile-max-width) {
  .list {
    .element {
      border-bottom: 2px solid $primary-color;
      margin: 0;

      h2 {
        font-size: 40px;
        max-width: 95vw;
      }

      a {
        height: 16em;

        img {
          border-radius: 0;
          height: 16em;
          object-fit: cover;
          opacity: 0.3;
          transition: all 0s;
          width: 100vw;
        }

        img:hover {
          filter: grayscale(0);
        }
      }
    }
  }
}
