header {
  align-items: center;
  background: $primary-color;
  display: flex;
  justify-content: center;

  a {
    line-height: 0;

    img {
      border-radius: 100%;
      height: 100px;
      width: 100px;
    }
  }

  p {
    color: $white;
    font-size: 32px;
    font-weight: bold;
    padding: 1em 4em 1em 1em;
    text-align: center;

    a {
      font-size: 32px;
      cursor: default;
      color: $white;
    }

    a:hover {
      cursor: default;
      color: $white;
      text-decoration: none;
    }
  }
}

@media (max-width: $mobile-max-width) {
  header {
    p {
      font-size: 24px;

      a {
        font-size: 24px;
      }
    }
  }
}
