.list {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  .card {
    border: 0.4em solid $primary-color;
    border-radius: 1em;
    display: flex;
    flex-direction: column;
    margin: 1em;
    padding: 1em 2em;
    width: 12.5em;

    h2 {
      padding: 0.5em 0;
      text-align: left;
    }

    p {
      padding: 0.1em 0;
    }
  }
}

@media (max-width: $mobile-max-width) {
  .card {
    margin-bottom: 2em;
  }
}
