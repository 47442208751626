@media (max-width: $mobile-max-width) {
  $color: $white;
  $height-icon: 30px;
  $width-line: 40px;
  $height-line: 4px;
  $transition-time: 0.5s;
  $rotation: 45deg;
  $translate-y: (($height-icon / 2) - 2.5);
  $translate-x: 0;

  #hamburger {
    border-radius: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: $primary-color;
    height: $height-icon;
    position: fixed;
    right: 1em;
    top: 1em;
    width: $width-line;
    padding: 1.45em 1em;
    z-index: $z-index-hamburger;

    .line {
      background: $color;
      width: $width-line;
      height: $height-line;
      left: 0;
      border-radius: ($height-line / 2);
      transition: all $transition-time;

      &.line-1 {
        top: 0;
      }

      &.line-2 {
        top: 50%;
      }

      &.line-3 {
        top: 100%;
      }
    }

    &:hover,
    &:focus {
      .line-1 {
        transform: translateY($height-line / 2 * -1);
      }

      .line-3 {
        transform: translateY($height-line / 2);
      }
    }

    &.active {
      .line-1 {
        transform: translateY($translate-y) translateX($translate-x) rotate($rotation);
      }

      .line-2 {
        opacity: 0;
      }

      .line-3 {
        transform: translateY($translate-y * -1) translateX($translate-x) rotate($rotation * -1);
      }
    }
  }
}
