form {
  margin: 0 auto;
  max-width: 25em;

  .alert {
    align-items: center;
    color: $white;
    display: flex;
    font-size: 16px;
    padding: 1em;
    margin: -2em 0 1em;

    i {
      margin: 0 1em;
    }
  }

  .danger {
    background: $darkred;
  }

  .info {
    background: $info;

    a {
      color: white;
      text-decoration: none;
    }

    a:hover {
      text-decoration: underline;
    }
  }

  .login {
    display: flex;
    flex-direction: column;

    div {
      align-items: center;
      display: flex;
      justify-content: center;
      margin: 0 0 1em;

      label {
        color: $gray;
        margin: 0 1em 0 0;
      }

      input {
        background: $lightgray;
        border: 0;
        border-radius: 0.3em;
        font-size: 16px;
        padding: 0.5em 2em;
      }

      input:focus {
        outline: 2px solid $primary-color;
      }
    }

    button {
      background: $primary-color;
      border: none;
      border-radius: 2em;
      color: $white;
      font-size: 16px;
      margin: 1em 0 2em;
      width: 10em;
      padding: 0.5em 2em;
      transition: background 0.7s;
    }

    button:hover {
      background: $secondary-color;
    }
  }
}
