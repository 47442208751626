// Variables
$mobile-max-width: 991.98px;
$big-screen-min-width: 1538.01px;
$big-screen-width: 1230.01px;

// z-Indexes
$z-index-sticky-nav: 10;
$z-index-hamburger: 100;

// Colors
$primary-color: #036975;
$secondary-color: #099dbf;
$background-body: #dcdcdc;
$background-container: #ececec;

// Standard
$black: #000;
$white: #fff;
$red: #f38c8c;
$darkred: #b95309;
$darkgray: #454545;
$gray: lightgray;
$lightgray: #dcdcdc;

// Elements
$link: #2390de;
$danger: $darkred;
$info: #4c93fa;

// Easyadmin

/*
:root {
  --sidebar-bg: #036975;
} */
