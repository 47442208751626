.news {
  align-items: start;
  border-top: 0.2em solid $gray;
  display: flex;
  flex-direction: column;
  padding: 5em 0;

  .news-header {
    padding: 0 0 2em;

    .news-image {
      max-width: 100%;
    }

    .info-bar {
      background: $lightgray;
      border-radius: 0 10px 10px 0;
      display: flex;
      flex-direction: column;
      margin: -11em 0 3em;
      padding: 2em 0 1em;
      opacity: 0.9;
      width: 80%;
    }

    h2 {
      color: $primary-color;
      font-size: 45px;
      padding: 0 0 0 1em;
      text-align: left;
    }

    p {
      color: $darkgray;
      padding: 0.5em 1em 0 0;
      text-align: right;
    }
  }

  .news-content {
    font-size: 18px;
    margin: 0 auto;
    line-height: 1.5;
    width: 90%;
  }
}

.news:first-of-type {
  border-top: none;
}

@media (max-width: $mobile-max-width) {
  .news {
    flex-direction: column;
    padding: 3em 0;

    .news-header {
      margin: 2em 0 0;

      .info-bar {
        margin: -10em 0 4em;
        width: 90%;
      }

      h2 {
        font-size: 24px;
      }
    }
  }
}
