.contact {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;

  a {
    border: 0.4em solid $primary-color;
    border-radius: 1em;
    display: flex;
    flex-direction: column;
    margin: 0 1em;
    padding: 2em 3em 1em;
    text-decoration: none;
    transition: background 0.5s, color 0.5s;

    i {
      color: $primary-color;
      font-size: 65px;
      text-align: center;
      transition: all 0.7s ease;
    }

    span {
      padding: 0.5em 0 0;
      text-align: center;
      font-size: 24px;
      width: 85px;
    }
  }

  a:hover {
    border: 0.4em solid $secondary-color;
    background: $lightgray;

    i {
      color: $secondary-color;
    }
  }
}

table {
  border-collapse: collapse;
  font-size: 22px;
  margin: 0 auto;
  width: 100%;

  tbody {
    tr {
      transition: all 0.7s ease;
    }

    tr:nth-child(odd) {
      background-color: $lightgray;
    }

    td {
      padding: 0.5em;
      transition: all 0.7s ease;
    }

    tr:hover {
      background: $secondary-color;

      td {
        color: $white;
      }
    }
  }
}

.google-maps {
  margin: 0 auto;

  iframe {
    border: none;
    height: 25em;
    width: 100%;
  }
}

@media (max-width: $mobile-max-width) {
  .contact {
    margin: 1em 0 0;

    a {
      border: 0.3em solid $primary-color;
      padding: 0.5em 1.5em;
      margin-bottom: 2em;

      i {
        align-self: center;
        font-size: 60px;
        padding: 0.1em 0 0;
      }

      span {
        width: 85px;
      }
    }
  }

  table {
    font-size: 18px;

    tbody {
      td:first-of-type {
        padding: 0 0 0 1.5em;
      }
    }
  }
}
